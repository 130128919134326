import React, { Component } from "react";
import Result from "./Result";
import { Button, Input, Row, CardPanel } from "react-materialize";

class Form extends Component {
  state = {
    name: "",
    email: "",
    website: "",
    agree: false,
    submitted: false
  };

  handleChange = e => {
    const type = e.target.type;
    const name = e.target.name;
    const value = type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({
      [name]: value
    });
  };

  handleClearForm = e => {
    e.preventDefault();
    this.setState({
      name: "",
      email: "",
      website: "",
      agree: false,
      submitted: false
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.setState({ submitted: true });
  };

  render() {
    const { name, email, website, agree, submitted } = this.state;
    if (submitted) {
      return <Result name={name} email={email} website={website} />;
    } else {
      return (
        <div className="Layouts">
          <form className="Form" onSubmit={this.handleSubmit}>
            <Row>
              <Input
                type="text"
                name="name"
                required
                value={name}
                onChange={this.handleChange}
                label="Company name"
                s={4}
                className="blue-text"
              />
              <Input
                type="email"
                name="email"
                required
                value={email}
                onChange={this.handleChange}
                label="Email"
                s={4}
                className="blue-text"
              />
              <Input
                type="text"
                name="website"
                required
                value={website}
                onChange={this.handleChange}
                label="Website URL"
                s={4}
              />
            </Row>
            <Row>
              <Input
                type="checkbox"
                name="agree"
                value="agree"
                checked={agree}
                onChange={this.handleChange}
                label="I understand and agree to the disclaimer below."
                className="blue"
              />
            </Row>
            <Button
              type="submit"
              className="blue"
              disabled={agree ? false : true}
            >
              Generate T and C's
            </Button>
          </form>
          <br />
          <br />
          <CardPanel className="grey-text">
            <p>
              <small>
                Thrive Disclaimer: We offer no legal responsibility on how this general use policy is used and you must read and customise with the help of legal advice to fit your purpose and business practice. <a href="https://thriveweb.com.au"> Made by Thriveweb.com.au</a>.
              </small>
            </p>
          </CardPanel>
        </div>
      );
    }
  }
}

export default Form;
