import React, { Component, Fragment } from "react";

class Refund extends Component {
  render() {
    const { name, website } = this.props;
    return (
      <Fragment>
        <h2>Refund &amp; Returns Policy</h2>
        <p>
          This policy sets out the circumstances in which goods supplied by us
          to a customer ("you") will be accepted for return.
        </p>
        <p>
          <strong>AUSTRALIAN CONSUMER LAW</strong>
        </p>
        <p>
          The Australian Consumer Law (ACL) protects consumers by giving them
          certain guaranteed rights when they buy goods and services. For
          example, the ACL requires that goods must be free of defects, and do
          what they are meant to do. Services must be carried out with care and
          skill. These rights, which the ACL says automatically apply whenever
          goods or services are supplied to a consumer, are called ‘Consumer
          Guarantees’ Our goods come with guarantees that cannot be excluded
          under the Australian Consumer Law. You are entitled to a replacement
          or refund for a major failure and for compensation for any other
          reasonably foreseeable loss or damage. You are also entitled to have
          the goods repaired or replaced if the goods fail to be of acceptable
          quality and the failure does not amount to a major failure.
        </p>
        <p>
          <strong>CHANGE OF MIND RETURNS</strong>
        </p>
        <p>
          We will replace goods if they have been damaged during shipping.
          Notify us via email as soon as possible. You return the item within 30
          days of purchase.
        </p>
        <p>The Item is;</p>
        <ul>
          <li>Produce your original receipt at the time you return the item</li>
          <li>
            It is in its original packaging, including instruction manuals and
            all accessories;
          </li>
          <li>It is unopened, unused and in its original condition; and</li>
          <li>
            If these requirements are not satisfied we reserve the right not to
            offer an exchange or refund for change of mind returns.
          </li>
          <li>
            While goods are being assessed, we will not be deemed to have
            accepted the goods for return.
          </li>
        </ul>
        <p>
          <strong>WHEN GOODS WILL BE ACCEPTED FOR RETURN</strong>
        </p>
        <p>
          We will accept goods for return only after assessment as set out
          above, where the goods:
        </p>
        <ul>
          <li>The product is faulty or is not of acceptable quality, or</li>
          <li>The product does not match the sample or our description; and</li>
          <li>
            The product does not work as described for its intended purpose
          </li>
        </ul>
        <p>
          If you are entitled to a refund under the ACL, your refund will be
          issued to you by your original payment method for the goods.
        </p>
        <p>
          Goods presented for repair may be replaced by refurbished goods of the
          same type rather than being repaired. Refurbished parts may be used to
          repair goods.
        </p>
        <p>
          Claims for compensation or reasonable expenses incurred in returning
          your purchase should be addressed to our Customer Service team at the
          address below and need to be accompanied with supporting evidence.
        </p>
        <p>
          Our goods come with guarantees that cannot be excluded under the
          Australian Consumer Law. You are entitled to a replacement or refund
          for a major failure and for compensation for any other reasonably
          foreseeable loss or damage. You are also entitled to have the goods
          repaired or replaced if the goods fail to be of acceptable quality and
          the failure does not amount to a major failure.
        </p>
        <p>
          <strong>HOW TO RETURN YOUR PRODUCT</strong>
        </p>
        <p>
          Returns can be sent back to us, our address and location can be found
          on our contact page.
        </p>
        <p>
          You will need your tax invoice (email receipt) as information on this
          is needed as proof of purchase. For a change of mind return/exchange,
          the delivery fee is non-refundable.
        </p>
        <p>
          This policy is for{" "}
          <a href={website}>
            {name} - {website}
          </a>{" "}
          effective as of {new Date().getFullYear()}.
        </p>
      </Fragment>
    );
  }
}

export default Refund;
