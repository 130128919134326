import React, { Component } from "react";
import Form from "./components/Form";
import "./Styles.css";

class App extends Component {
  render() {
    return (
      <div className="App">
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/materialize/0.100.2/css/materialize.min.css"
        />
        <header className="App-header">
          <Form />
        </header>
      </div>
    );
  }
}

export default App;
