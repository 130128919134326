import React, { Component } from "react";
import { Input, Row, Chip } from "react-materialize";
import Privacy from "./ContentPrivacy";
import Terms from "./ContentTerms";
import Delivery from "./ContentDelivery";
import Security from "./ContentSecurity";
import Refund from "./ContentRefund";

class Result extends Component {
  state = {
    terms: true,
    privacy: false,
    delivery: false,
    security: false,
    refund: false
  };

  handleChange = e => {
    const name = e.target.name;
    this.setState({
      privacy: false,
      terms: false,
      delivery: false,
      security: false,
      refund: false,
      [name]: e.target.checked
    });
  };

  render() {
    let { name, email, website } = this.props;
    const pattern = /^((http|https|ftp):\/\/)/;
    if (!pattern.test(website)) {
      website = "http://" + website;
    }

    const { privacy, terms, delivery, security, refund } = this.state;
    return (
      <div className="Layouts">
        <Row>
          <Chip>
            <Input
              type="checkbox"
              name="terms"
              value="terms"
              checked={terms}
              onChange={this.handleChange}
              label="Terms of service"
            />
          </Chip>
          <Chip>
            <Input
              type="checkbox"
              name="privacy"
              value="privacy"
              checked={privacy}
              onChange={this.handleChange}
              label="Privacy"
            />
          </Chip>
          <Chip>
            <Input
              type="checkbox"
              name="delivery"
              value="delivery"
              checked={delivery}
              onChange={this.handleChange}
              label="Delivery"
            />
          </Chip>
          <Chip>
            <Input
              type="checkbox"
              name="security"
              value="security"
              checked={security}
              onChange={this.handleChange}
              label="Security"
            />
          </Chip>
          <Chip>
            <Input
              type="checkbox"
              name="refund"
              value="refund"
              checked={refund}
              onChange={this.handleChange}
              label="Refund"
            />
          </Chip>
        </Row>
        <div className={`Terms Section ${terms ? "active" : ""}`}>
          <Terms name={name} website={website} />
        </div>
        <div className={`Privacy Section ${privacy ? "active" : ""}`}>
          <Privacy name={name} website={website} />
        </div>
        <div className={`Delivery Section ${delivery ? "active" : ""}`}>
          <Delivery name={name} website={website} />
        </div>
        <div className={`Refund Section ${refund ? "active" : ""}`}>
          <Refund name={name} website={website} />
        </div>
        <div className={`Security Section ${security ? "active" : ""}`}>
          <Security name={name} website={website} />
        </div>
      </div>
    );
  }
}

export default Result;
