import React, { Component, Fragment } from "react";

class Security extends Component {
  render() {
    const { name, website } = this.props;
    return (
      <Fragment>
        <h2>Security Policy</h2>
        <p>
          All online credit card transactions performed on {website} use a
          secured payments by means of SSL encryption We use a payment gateway
          to process credit card payments. That gateway processes online credit
          card transactions for thousands of merchants, providing a safe and
          secure means of collecting payments via the Internet.
        </p>
        <p>
          Your complete credit card number cannot be viewed by us or any outside
          parties. All monies are directly transferred from your credit card to
          the merchant account held by us.
        </p>
        <p>
          For more information about the process and secure online credit card
          payments, please contact us.
        </p>
        <p>
          This policy is for{" "}
          <a href={website}>
            {name} - {website}
          </a>{" "}
          effective as of {new Date().getFullYear()}.
        </p>
      </Fragment>
    );
  }
}

export default Security;
