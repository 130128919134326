import React, { Component, Fragment } from "react";

class Delivery extends Component {
  render() {
    const { name, website } = this.props;
    return (
      <Fragment>
        <h2>Delivery Policy</h2>
        <p>
          We will be notified by the payment gateway containing your order
          details (providing you have supplied an email address) We will confirm
          your order within minutes. All goods will be sent out within three
          working days. For any reason, if goods are unavailable that are out of
          our control, the delivery may take longer.
        </p>
        <p>Enquiries on deliveries please contact us.</p>
        <p>
          This policy is for{" "}
          <a href={website}>
            {name} - {website}
          </a>{" "}
          effective as of {new Date().getFullYear()}.
        </p>
      </Fragment>
    );
  }
}

export default Delivery;
